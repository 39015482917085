import React from "react";
import { Row, Col, Typography } from "antd";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import CtaSecondary from "../../../components/Buttons/secondary-cta";
import ogImage from "../../../images/global_assets/og-image.png";
import LegalStyles from "../../../components/Layout/LegalStyles";

const { Title } = Typography;

const MerchantTerms = () => {
  return (
    <Layout isLanding useNewLPHeader className="consumer-privacy">
      <SEO
        title="SpotOn Ally | Ally Program Terms &amp; Conditions"
        description="Terms and conditions of the SpotOn Ally Affiliate Program"
        image={`https://spoton.com/${ogImage}`}
      />
      {/* <Affix offsetTop={0}> */}
      <div style={{ marginTop: 90 }}>
        <CtaSecondary
          target="/partners/become-an-ally"
          ctaTitle="Become an Ally"
          reverseIcon
        />
      </div>
      {/* </Affix> */}
      <main className="consumer-privacy__data">
        <Row align="middle">
          <Col lg={20} sm={24}>
            <div>
              <Title level={1}>Ally Partner Program</Title>
              <Title level={3}>Terms & Conditions (as of 5/1/24)</Title>
              <Title level={2}>Partner Eligibility</Title>
              <Title level={3}>Eligible for Ally.</Title>
              <p>
                Restaurant consultants, professional services, brokers,
                equipment sales people, networking group leaders, marketing
                agencies, IT networking consultants and companies, social media
                influencers, content creators, dealers, and local businesses.
              </p>
              <Title level={3}>Ineligible</Title>
              <p>
                Any individual subject to the rules of any other SpotOn referral
                program, as well as the immediate family or household members of
                a disqualified individual. For the purposes of these Terms and
                Conditions, “immediate family or household members” includes,
                but is not limited to, spouses, domestic partners, parents,
                grandparents, children, grandchildren, siblings, step-parents,
                step-children, step-siblings, and their respective spouses or
                domestic partners and individuals who share the same residence
                at least three (3) months per calendar year, whether or not
                related by blood or marriage.
              </p>
              <ul className="text-[18px]">
                <li>
                  SpotOn Employees.
                  <p className="pl-2 mb-[0px]">
                    Current SpotOn Employees, as well as their immediate family
                    and household members, are ineligible to participate.
                  </p>
                  <ul>
                    <li>
                      Former SpotOn employees who left in good standing, are
                      eligible for an exception request to join the program.{" "}
                    </li>
                    <li>
                      In order to request an exception for former employees
                      interested in joining the Ally program, who left in good
                      standing, please email allyprogram@spoton.com with the
                      subject line “Former Employee Ally Exception Request”.{" "}
                    </li>
                    <li>
                      Please allow up to 5 business days for review and
                      investigation on all Exception Requests.
                    </li>
                  </ul>
                </li>
                <li>
                  <p className="pl-2">
                    Employees of SpotOn technical integration and strategic
                    partners with existing referral programs, including
                    immediate family and household members
                  </p>
                </li>
                <li>
                  <p>SpotOn Merchants.</p>
                  <p className="pl-2">
                    SpotOn merchants are not eligible but can submit referrals
                    via the Merchant Referral Program from their dashboard. This
                    includes any individual holding any direct ownership
                    interest in the business or the business’ day-to-day
                    operations.
                  </p>
                </li>
                <li>
                  Employees of any business participating in SpotOn’s Strategic
                  Partner Program.
                </li>
                <li>
                  <p className="font-bold">
                    Employees of any business engaged in a corporate partnership
                    agreement with SpotOn (including but not limited to
                    Strategic Partners and Integration Partners).
                  </p>
                  <ul>
                    <li>
                      Any active Ally partner of SpotOn, whose employer later
                      enters into a corporate partnership agreement with SpotOn,
                      will no longer be eligible to participate in the Ally
                      Program and the Ally Partner Agreement will be null and
                      void.
                    </li>
                  </ul>
                </li>
                <li>
                  <p className="pl-2 font-bold">
                    SpotOn dealers/resellers and their employees.
                  </p>
                </li>
                <li>
                  <p className="pl-2 font-bold">
                    Individuals who are under 18 years old.
                  </p>
                </li>
                <li>
                  <p className="pl-2 font-bold">Non-U.S. Citizens.</p>
                </li>
              </ul>
              <sub className="font-thin text-[8px] mb-4">
                *It is the sole discretion of SpotOn to deny participation
                eligibility in the SpotOn Ally Program.
              </sub>
              <Title level={2}>Referrals</Title>
              <Title level={3}>Qualified Referrals.</Title>
              <p>
                Subject to these Terms, for each “Qualified Referral” that meets
                the following criteria, you will receive the applicable Bonus
                Payout listed below.
              </p>
              <ol>
                <li>
                  <p className="pl-2 font-bold">
                    Lead is submitted, by the Ally partner, using their unique
                    referral link;
                  </p>
                  <ul>
                    <li>
                      Verbal notice or emails delivered directly to a SpotOn
                      representative will not be accepted as a proper submission
                    </li>
                  </ul>
                </li>
                <li>
                  <p className="pl-2 font-bold">
                    Lead is submitted PRIOR to informing their respective SpotOn
                    Sales Representative;
                  </p>
                  <ul>
                    <li>
                      If a SpotOn Sales Representative begins communication with
                      a referred contact PRIOR to receiving the formal referral
                      via unique link, said referral will be ineligible to
                      receive the Referral Bonus and will result in
                      disqualification.
                    </li>
                    <li>
                      The referral closed must originate from the unique Ally
                      submission. Duplicate entries later created in the SpotOn
                      Customer Management Relationship system will not qualify
                      for a referral payout.
                    </li>
                  </ul>
                </li>
                <li>
                  <p className="pl-2 font-bold">
                    Lead contains specific contact information for the referred
                    prospect; AND
                  </p>
                  <p className="pl-2">
                    Personal contact information of the prospective business
                    owner must be provided in order to qualify for Bonus
                    eligibility.
                  </p>
                  <ul>
                    <li>
                      This includes, but is not limited to:{" "}
                      <ul>
                        <li>Cell phone number</li>
                        <li>Personal/direct contact email</li>
                        <li>
                          Business phone number only when accompanied by a
                          specific extension for that individual
                        </li>
                      </ul>
                    </li>
                    <li>
                      Any generic business information provided (e.g.
                      info@businessname.com, business phone number, etc) that is
                      available publicly will be disqualified and ineligible for
                      Bonus payment.
                    </li>
                  </ul>
                </li>
                <li>
                  <p className="pl-2 font-bold">
                    The Lead is not an existing prospect (lead, account, or
                    opportunity) in the SpotOn Customer Relationship Management
                    system.
                  </p>
                </li>
              </ol>
              {/* BREAK */}
              <Title level={3}>Disqualified Referrals.</Title>
              <ul>
                <li>
                  <p className="pl-2 font-bold">
                    Referrals made to a business that the Ally partner holds any
                    ownership/financial interest in;
                  </p>
                </li>
                <li>
                  <p className="pl-2 font-bold">
                    Referrals made to a business that the Ally partner is a
                    directly employed decision maker responsible for the
                    procurement of making the POS purchase.
                  </p>
                  <ul>
                    <li className="pl-2">
                      Independent consultants contracted outside of normal,
                      direct employment may still provide qualified referrals.
                    </li>
                  </ul>
                </li>
                <li>
                  <p className="pl-2 font-bold">
                    Add-On services or products for existing merchants.
                  </p>
                </li>
                <li>
                  <p className="pl-2 font-bold">
                    Deals associated with a COO (Change of Ownership) and/or
                    COB’s (Change of Business) of an existing SpotOn Merchant.
                  </p>
                </li>
                <li>
                  <p className="pl-2 font-bold">
                    Referrals of prior/canceled SpotOn merchants.
                  </p>
                  <ul>
                    <li>
                      Exception (must meet both):{" "}
                      <ul>
                        <li>
                          The merchant purchases a new point of sale solution
                          from SpotOn that was not offered by SpotOn at the time
                          the original SpotOn POS solution was purchased; AND
                        </li>
                        <li>
                          The SpotOn representative who was previously
                          associated with the merchant is not the same person
                          submitting the deal associated with the referral.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
              <Title level={3}>Multi-Unit Referrals.</Title>
              <p>
                Referrals of multiple locations that are legally associated
                (franchises, restaurant groups, etc.) must be submitted
                separately (each unique location address and contact
                information) in order for each to qualify for a referral bonus.
              </p>
              <ul>
                <li>
                  Only those locations submitted during the initial referral and
                  activated within twelve (12) months of the original submission
                  will qualify.
                </li>
              </ul>

              <Title level={2}>Bonus Payouts</Title>
              <Title level={3}>Rule.</Title>
              <p>
                Ally partners will be eligible to receive a bonus payout for a
                Qualified Referral when:
              </p>
              <ol>
                <li>
                  <p>The Ally has been fully onboarded;</p>
                  <ul>
                    <li>
                      Fills out the online form to sign up for the Ally Partner
                      Program;
                    </li>
                    <li>
                      Interviews with a SpotOn Ally Partner Program manager; AND
                    </li>
                    <li>
                      Completes and returns the referral agreement, W9 form and
                      direct deposit authorization form.
                    </li>
                  </ul>
                </li>
                <li>
                  <p className="pl-2 font-bold">
                    Qualified Referral submitted properly; AND
                  </p>
                </li>
                <li>
                  <p className="pl-2 font-bold">
                    The Referred Client signs with, and activates their account,
                    within 12 months of the Referral Submission date.
                  </p>
                  <ul>
                    <li>
                      Activation has been met when the new, referred client has
                      both:
                      <ul>
                        <li>Has installed their SpotOn POS; AND</li>
                        <li>
                          Surpasses $25,000 in total credit card sales processed
                          through the SpotOn POS.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ol>
              <Title level={3}>Amount</Title>
              <ul>
                <li>
                  Ally partners submitting a qualified referral that then
                  activates their account with SpotOn will receive a referral
                  payout according to the type of business referred.
                </li>
                <li>
                  <p>
                    The “type of business referred” shall be strictly defined by
                    the SpotOn product(s) implemented.
                  </p>
                  <ul>
                    <li>Restaurant POS: $1,000</li>
                    <li>Express: $1,000</li>
                    <li>Retail: $250</li>
                  </ul>
                </li>
              </ul>
              <Title level={3}>Method</Title>
              <p>
                All referral payouts shall be in the form of an ACH payment sent
                to the bank account indicated on their direct deposit
                authorization form provided during Onboarding.
              </p>
              <Title level={3}>Timing.</Title>
              <p>
                Payment will be initiated the first week of the month
                immediately following the date of the referred account’s
                activation (defined, above).
              </p>
              <Title level={3}>Limits.</Title>
              <p>
                Multi-location referrals carry a maximum bonus payout of $10,000
                in total.
              </p>
              <p>
                * SpotOn merchants can access their dashboard, here{" "}
                <a
                  href="https://dashboard.spoton.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://dashboard.spoton.com/.
                </a>{" "}
                All users must have a username and password to login and view
                their dashboard.
              </p>
            </div>
          </Col>
        </Row>
      </main>

      <LegalStyles />
    </Layout>
  );
};

export default MerchantTerms;
